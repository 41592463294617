import React, { Component, createRef } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import omit from 'lodash/omit';
import classNames from 'classnames';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import config from '../../config';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { isAnyFilterActive, isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import { Footer, Page } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';

import FilterComponent from './FilterComponent';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import moment from 'moment';
import css from './SearchPage.module.css';
import { withViewport } from '../../util/contextHelpers';
import TopbarSearchForm from '../../components/Topbar/TopbarSearchForm/TopbarSearchForm';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const validUrlQueryParamsFromProps = (props) => {
  const { location, filterConfig } = props;
  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  return validURLParamsForExtendedData(searchInURL, filterConfig);
};

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      showAllBusinessSector: false,
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
    this.handleLocationClear = this.handleLocationClear.bind(this);
    this.handleDateClear = this.handleDateClear.bind(this);
  }
  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = isOriginInUse(config) ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = filterConfig.map((f) => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, {}));
  }

  initialValues(queryParamNames, isLiveEdit) {
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = (paramName) => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState && !isLiveEdit ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }
  getHandleChangedValueFn(useHistoryPush) {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const { address, bounds, keywords } = urlQueryParams;
    return (updatedURLParams) => {
      const newBounds = updatedURLParams?.bounds ? updatedURLParams.bounds : undefined;
      const newAddress = updatedURLParams?.address ? updatedURLParams.address : undefined;
      const updater = (prevState) => {
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };
        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        // const keywordsMaybe = keywords ? { keywords } : {};
        if (newAddress && newBounds) {
          return {
            currentQueryParams: {
              ...mergedQueryParams,
              ...updatedURLParams,
              // ...keywordsMaybe,
              ...(newAddress ? { address: newAddress } : {}),
              ...(newBounds ? { bounds: newBounds } : {}),
            },
          };
        } else {
          return {
            currentQueryParams: {
              ...mergedQueryParams,
              ...updatedURLParams,
              // ...keywordsMaybe,
              address,
              bounds,
            },
          };
        }
      };

      const callback = () => {
        if (newAddress !== undefined && newBounds === undefined) {
          return;
        } else if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleLocationClear() {
    const { history } = this.props;
    const search = { ...this.state.currentQueryParams, address: undefined, bounds: undefined };
    // history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));

    this.setState(
      (prevState) => ({ currentQueryParams: { ...prevState.currentQueryParams, ...search } }),
      () => {
        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
      }
    );
  }
  handleDateClear() {
    const { history } = this.props;
    const search = { ...this.state.currentQueryParams, pub_businessEstDateSearch: undefined };
    this.setState(
      (prevState) => ({ currentQueryParams: { ...prevState.currentQueryParams, ...search } }),
      () => {
        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
      }
    );
  }
  handleSortBy(urlParam, values) {
    const { history } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  // Reset all filter query parameters
  handleResetAll(e) {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }
  // handleSubmit(values) {
  //   const { currentSearchParams } = this.props;
  //   const { history } = this.props;
  //   const topbarSearchParams = () => {
  //     if (isMainSearchTypeKeywords(config)) {
  //       return { keywords: values?.keywords };
  //     }
  //     // topbar search defaults to 'location' search
  //     const { search, selectedPlace } = values?.location;
  //     const { origin, bounds } = selectedPlace;
  //     const originMaybe = isOriginInUse(config) ? { origin } : {};

  //     return {
  //       ...originMaybe,
  //       address: search,
  //       bounds,
  //     };
  //   };
  //   const searchParams = {
  //     ...currentSearchParams,
  //     ...topbarSearchParams(),
  //   };

  //   history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  // }
  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      history,
      currentUser,
      viewport,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, keywords, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const handleMoreBusinessSectorBtn = (
      <button
        type="button"
        className={css.mrebnssctr}
        onClick={() => {
          if (
            this.state.showAllBusinessSector &&
            typeof window !== 'undefined' &&
            viewport.width > 767
          ) {
            window.scrollTo({ top: 500, behavior: 'smooth' });
          }
          this.setState((pstate) => ({
            showAllBusinessSector: !pstate.showAllBusinessSector,
          }));
        }}
      >
        {this.state.showAllBusinessSector ? (
          <>
            <span>Less</span>
            <IoIosArrowUp />
          </>
        ) : (
          <>
            <span>More</span>
            <IoIosArrowDown />
          </>
        )}
      </button>
    );

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    // const availableFilters = false
    //   ? filterConfig.filter((f) => f.type !== 'KeywordFilter')
    //   : filterConfig;
    const availableFilters = filterConfig.filter(
      (f) =>
        f.id === 'businessSector' ||
        f.id === 'businessTypes' ||
        f.id === 'keyword' ||
        f.id === 'price' ||
        f.id === 'location' ||
        f.id === 'businessEstDateSearch'
    );
    let businessSectorFilterOptns;
    if (this.state.showAllBusinessSector) {
      businessSectorFilterOptns = availableFilters.find((f) => f.id === 'businessSector');
    } else {
      businessSectorFilterOptns = {
        ...availableFilters.find((f) => f.id === 'businessSector'),
        config: {
          schemaType: 'enum',
          options: availableFilters
            .find((f) => f.id === 'businessSector')
            .config.options.filter((f, idx) => idx < 10),
        },
      };
    }

    const sortedAvailableFilters = [
      availableFilters.find((f) => f.id === 'keyword'),
      availableFilters.find((f) => f.id === 'location'),
      availableFilters.find((f) => f.id === 'price'),
      availableFilters.find((f) => f.id === 'businessTypes'),
      businessSectorFilterOptns,
      availableFilters.find((f) => f.id === 'businessEstDateSearch'),
    ];
    // Selected aka active filters
    const selectedFilters = validFilterParams(validQueryParams, filterConfig);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter((f) => f !== 'keywords').length
      : keysOfSelectedFilters.length;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;
    // console.log(2152, listingsAreLoaded);
    const sortBy = (mode) => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        validQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : { className: css.sortByDesktop };
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchInURL || {},
      intl
    );

    const hasNoResult = listingsAreLoaded && totalItems === 0;
    const hasSearchParams = location.search?.length > 0;
    const noResultsInfo = hasNoResult ? (
      <div className={css.noSearchResults}>
        <FormattedMessage id="SearchPage.noResults" />
        <br />
        {hasSearchParams ? (
          <button className={css.resetAllFiltersButton} onClick={(e) => this.resetAll(e)}>
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </button>
        ) : null}
      </div>
    ) : null;

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>

    const selEndDateMaybeInTs =
      this.state.currentQueryParams['pub_businessEstDateSearch'] &&
      this.state.currentQueryParams['pub_businessEstDateSearch'].indexOf(',') === 0
        ? this.state.currentQueryParams['pub_businessEstDateSearch']?.split(',')[1]
        : this.state.currentQueryParams['pub_businessEstDateSearch'] &&
          (this.state.currentQueryParams['pub_businessEstDateSearch'].indexOf(',') !== 0 ||
            this.state.currentQueryParams['pub_businessEstDateSearch'].indexOf(',') !==
              this.state.currentQueryParams['pub_businessEstDateSearch'].length - 1)
        ? this.state.currentQueryParams['pub_businessEstDateSearch']?.split(',')[1]
        : null;
    const selEndDateMaybe =
      selEndDateMaybeInTs !== null && selEndDateMaybeInTs !== '' && selEndDateMaybeInTs !== 0
        ? moment.unix(selEndDateMaybeInTs).format('YYYY/MM/DD')
        : null;
    const selStartDateMaybeInTs =
      this.state.currentQueryParams['pub_businessEstDateSearch'] &&
      this.state.currentQueryParams['pub_businessEstDateSearch'].indexOf(',') !== 0
        ? this.state.currentQueryParams['pub_businessEstDateSearch']?.split(',')[0]
        : null;
    const selStartDateMaybe =
      selStartDateMaybeInTs !== null && selStartDateMaybeInTs !== '' && selStartDateMaybeInTs !== 0
        ? moment.unix(selStartDateMaybeInTs).format('YYYY/MM/DD')
        : null;
    const selectedKeyword =
      this.state.currentQueryParams['keywords'] &&
      this.state.currentQueryParams?.keywords !== '' ? (
        <div className={css.selsearch} id="selectedKeyword">
          <span>{this.state.currentQueryParams.keywords}</span>{' '}
          <AiOutlineCloseCircle
            onClick={() => {
              this.setState(
                (state) => ({
                  currentQueryParams: { ...state.currentQueryParams, keywords: undefined },
                }),
                () => {
                  history.push(
                    createResourceLocatorString(
                      'SearchPage',
                      routeConfiguration(),
                      {},
                      this.state.currentQueryParams
                    )
                  );
                }
              );
            }}
          />
        </div>
      ) : null;
    const selectedBusinessType = this.state.currentQueryParams['pub_businessTypeSearch']
      ? this.state.currentQueryParams['pub_businessTypeSearch']?.split(',').map((item, idx) => (
          <div className={css.selsearch} id="selectedBusinessType" key={idx}>
            <span>
              {
                availableFilters
                  .find((f) => f.id === 'businessTypes')
                  .config.options.find((f) => f.value === item).label
              }
            </span>
            <AiOutlineCloseCircle
              onClick={() => {
                this.setState(
                  (state) => ({
                    currentQueryParams: {
                      ...state.currentQueryParams,
                      pub_businessTypeSearch:
                        state.currentQueryParams.pub_businessTypeSearch?.split(',').length > 1
                          ? state.currentQueryParams.pub_businessTypeSearch
                              ?.split(',')
                              .filter((f) => f !== item)
                              ?.join(',')
                          : undefined,
                    },
                  }),
                  () => {
                    history.push(
                      createResourceLocatorString(
                        'SearchPage',
                        routeConfiguration(),
                        {},
                        this.state.currentQueryParams
                      )
                    );
                  }
                );
              }}
            />
          </div>
        ))
      : null;
    const selectedBusinessSectorSearch = this.state.currentQueryParams['pub_businessSectorSearch']
      ? this.state.currentQueryParams['pub_businessSectorSearch']?.split(',').map((item, idx) => (
          <div className={css.selsearch} id="selectedBusinessSector" key={idx}>
            <span>
              {
                availableFilters
                  .find((f) => f.id === 'businessSector')
                  .config.options.find((f) => f.value === item).label
              }
            </span>
            <AiOutlineCloseCircle
              onClick={() => {
                this.setState(
                  (state) => ({
                    currentQueryParams: {
                      ...state.currentQueryParams,
                      pub_businessSectorSearch:
                        state.currentQueryParams.pub_businessSectorSearch?.split(',').length > 1
                          ? state.currentQueryParams.pub_businessSectorSearch
                              ?.split(',')
                              .filter((f) => f !== item)
                              ?.join(',')
                          : undefined,
                    },
                  }),
                  () => {
                    history.push(
                      createResourceLocatorString(
                        'SearchPage',
                        routeConfiguration(),
                        {},
                        this.state.currentQueryParams
                      )
                    );
                  }
                );
              }}
            />
          </div>
        ))
      : null;
    const selectedBusinessEstDateSearch = this.state.currentQueryParams[
      'pub_businessEstDateSearch'
    ] ? (
      <div className={css.selsearch} id="selectedDate">
        <span>{`${selStartDateMaybe ? selStartDateMaybe.replace(/\//g, '-') : '01/01/1970'} - ${
          selEndDateMaybe ? selEndDateMaybe.replace(/\//g, '-') : 'Future Date'
        }`}</span>{' '}
        <AiOutlineCloseCircle
          onClick={() => {
            this.setState(
              (state) => ({
                currentQueryParams: {
                  ...state.currentQueryParams,
                  pub_businessEstDateSearch: undefined,
                },
              }),
              () => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration(),
                    {},
                    this.state.currentQueryParams
                  )
                );
              }
            );
          }}
        />
      </div>
    ) : null;
    const selectedAddress = this.state.currentQueryParams.address ? (
      <div className={css.selsearch} id="selectedAddress">
        <span>{this.state.currentQueryParams['address']}</span>{' '}
        <AiOutlineCloseCircle
          onClick={() => {
            this.setState(
              (state) => ({
                currentQueryParams: {
                  ...state.currentQueryParams,
                  address: undefined,
                  bounds: undefined,
                },
              }),
              () => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration(),
                    {},
                    this.state.currentQueryParams
                  )
                );
              }
            );
          }}
        />
      </div>
    ) : null;
    const selectedPrice = this.state.currentQueryParams['price'] ? (
      <div className={css.selsearch} id="selectedBusinessSector">
        <span>
          {`$${this.state.currentQueryParams['price']?.split(',')[0]} - $${
            this.state.currentQueryParams['price']?.split(',')[1]
          }`}
        </span>
        <AiOutlineCloseCircle
          onClick={() => {
            this.setState(
              (state) => ({
                currentQueryParams: { ...state.currentQueryParams, price: undefined },
              }),
              () => {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration(),
                    {},
                    this.state.currentQueryParams
                  )
                );
              }
            );
          }}
        />
      </div>
    ) : null;
    // const topbarSearcInitialValues = () => {
    //   if (isMainSearchTypeKeywords(config)) {
    //     return { keywords };
    //   }

    //   // Only render current search if full place object is available in the URL params
    //   const locationFieldsPresent = isOriginInUse(config)
    //     ? address && origin && bounds
    //     : address && bounds;
    //   return {
    //     location: locationFieldsPresent
    //       ? {
    //           search: address,
    //           selectedPlace: { address, origin, bounds },
    //         }
    //       : null,
    //   };
    // };
    // const initialSearchFormValues = topbarSearcInitialValues();

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.layoutWrapperContainer}>
          <div className={css.lftColumn}>
            <aside className={css.layoutWrapperFilterColumn}>
              <div className={css.filterColumnContent}>
                {sortedAvailableFilters.map((config) => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.id}`}
                      idPrefix="SearchFiltersMobile"
                      className={css.filter}
                      filterConfig={config}
                      urlQueryParams={urlQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      liveEdit
                      showAsPopup={false}
                      handleLocationClear={this.handleLocationClear}
                      handleDateClear={this.handleDateClear}
                      isDesktop
                      handleMoreBusinessSectorBtn={handleMoreBusinessSectorBtn}
                      selEndDateMaybe={selEndDateMaybe}
                      selStartDateMaybe={selStartDateMaybe}
                    />
                  );
                })}
                <button className={css.resetAllButton} onClick={(e) => this.handleResetAll(e)}>
                  <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                </button>
              </div>
            </aside>
          </div>

          <div className={css.layoutWrapperMain} role="main">
            {/* <div>
              <TopbarSearchForm
                className={css.searchBar}
                onSubmit={this.handleSubmit}
                initialValues={initialSearchFormValues}
                isMobile
                appConfig={config}
              />
            </div> */}

            <div className={css.searchResultContainer}>
              {this.state.currentQueryParams['keywords'] ||
              this.state.currentQueryParams['pub_businessTypeSearch'] ||
              this.state.currentQueryParams['pub_businessSectorSearch'] ||
              this.state.currentQueryParams['pub_businessEstDateSearch'] ||
              this.state.currentQueryParams['address'] ||
              this.state.currentQueryParams['price'] ? (
                <div className={css.seldsearchs}>
                  {selectedKeyword}
                  {selectedBusinessSectorSearch}
                  {selectedBusinessType}
                  {selectedAddress}
                  {selectedPrice}
                  {selectedBusinessEstDateSearch}
                </div>
              ) : null}
              <SearchFiltersMobile
                className={css.searchFiltersMobileList}
                urlQueryParams={validQueryParams}
                sortByComponent={sortBy('mobile')}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onMapIconClick={onMapIconClick}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={this.onOpenMobileModal}
                onCloseModal={this.onCloseMobileModal}
                resetAll={this.resetAll}
                selectedFiltersCount={selectedFiltersCountForMobile}
                isMapVariant={false}
                noResultsInfo={noResultsInfo}
                scrollLayerClassName={css.modalScrollLayer}
              >
                {sortedAvailableFilters.map((config) => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.id}`}
                      idPrefix="SearchFiltersMobile"
                      filterConfig={config}
                      urlQueryParams={validQueryParams}
                      currUrlQueryParams={this.state.currentQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      liveEdit
                      showAsPopup={false}
                      handleMoreBusinessSectorBtn={handleMoreBusinessSectorBtn}
                      selEndDateMaybe={selEndDateMaybe}
                      selStartDateMaybe={selStartDateMaybe}
                      history={history}
                    />
                  );
                })}
              </SearchFiltersMobile>
              {/* <MainPanelHeader
                className={css.mainPanel}
                sortByComponent={sortBy('desktop')}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                noResultsInfo={noResultsInfo}
              /> */}
              <div
                className={classNames(css.listings, {
                  // [css.newSearchInProgress]: listingsAreLoaded === false,
                })}
              >
                {searchListingsError ? (
                  <h2 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </h2>
                ) : null}
                <SearchResultsPanel
                  className={css.searchListingsPanel}
                  listings={listings}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={parse(location.search)}
                  isMapVariant={false}
                  intl={intl}
                  history={history}
                  currentUser={currentUser}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { currentPageResultIds, pagination, searchInProgress, searchListingsError, searchParams } =
    state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);

  return {
    listings: pageListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(SearchPageComponent);

export default SearchPage;
